// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-front-js": () => import("./../src/templates/front.js" /* webpackChunkName: "component---src-templates-front-js" */),
  "component---src-templates-posts-js": () => import("./../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-projects-js": () => import("./../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-project-js": () => import("./../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-project-category-js": () => import("./../src/templates/projectCategory.js" /* webpackChunkName: "component---src-templates-project-category-js" */),
  "component---src-templates-project-industry-js": () => import("./../src/templates/projectIndustry.js" /* webpackChunkName: "component---src-templates-project-industry-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

